import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { StatusDTO } from 'src/app/shared/Models/Models';
import { StepCompletionStatusComponent } from 'src/app/shared/step-completion-status/step-completion-status.component';
import { Router } from "@angular/router"

@Component({
  selector: 'app-regiter-subscription-plan',
  templateUrl: './register-change-subscription-plan.component.html',
  styleUrls: ['./register-change-subscription-plan.component.scss']
})
export class RegisterChangeSubscriptionPlanComponent implements OnInit {


  radioSelection: string = "1";
  private planNo: string;
  private subPackage: string;

  constructor(private dialog: MatDialog, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((snap: any) => {
      console.log(snap);
      if ((!snap.params.planNo || snap.params.planNo && snap.params.planNo.length === 0) ||
        (!snap.params.subPackage || snap.params.subPackage && snap.params.subPackage.length === 0)) {
        this.router.navigate(['/SelectPackage']);
      }
      
        this.planNo = snap.params.planNo;
        this.subPackage = snap.params.subPackage;
    });
  }



  ManageConfirmClick() {

    this.router.navigate(['Register'], { queryParams: { planNo: this.planNo, subPackage: this.subPackage } });
  }
  //   let statusData: StatusDTO = {
  //     title: 'Package Subscription Confirmed',
  //     details: 'Customer’s subscription has been updated to the Premium package for a 2 year period.',
  //     stepSuccessful: true
  //   }


  //   this.dialog.open(StepCompletionStatusComponent,
  //     { height: '48vh', width: '42vw', data: statusData })
  // }
}
