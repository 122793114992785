import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from './core/auth/admin-guard.service';
import { AuthGuard } from './core/auth/auth-guard.service';
import { ChangeSubscriptionPlanComponent } from './customer-care-portal/subscription-and-discounts/change-subscription-plan/change-subscription-plan.component';
import { ChangeSubscriptionComponent } from './customer-care-portal/subscription-and-discounts/change-subscription/change-subscription.component';
import { PackageCardComponent } from './customer-care-portal/subscription-and-discounts/package-card/package-card.component';
import { ForgotPasswordComponent } from './login-screen/forgot-password/forgot-password.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { RegisterChangeSubscriptionPlanComponent } from './packageSelection/change-subscription-plan/register-change-subscription-plan.component';
import { RegisterChangeSubscriptionComponent } from './packageSelection/change-subscription/register-change-subscription.component';
import { RegisterComponent } from './register/register.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo:'SelectPackage'
  },
  {
    path: 'SelectPackage',
    component: RegisterChangeSubscriptionComponent,
  },
  {
    path: 'PlanSelection',
    component: RegisterChangeSubscriptionPlanComponent
  },
  {
    path: 'PlanConfirmation',
    component: ChangeSubscriptionPlanComponent
  },
  {
    path: 'DealerPortal',
    loadChildren: () => import('./dealers-portal/dealers-portal.module').then(m => m.DealersPortalModule)
  },
  {
    path: 'CustomerPortal',
    canActivate: [AuthGuard],
    loadChildren: () => import('./customer-care-portal/customer-care-portal.module').then(m => m.CustomerCarePortalModule)
  },
  {
    path: 'Login',
    loadChildren: () => import('./login-screen/login-screen.module').then(m => m.LoginScreenModule)
  },
  {
    path:'PaymentInfo',
    loadChildren: () => import('./payment-registeration/payment-registeration.module').then(m => m.PaymentRegisterationModule)
  },

  {
    path: 'Admin',
    canActivate:[AuthGuard, AdminGuard],
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'Register',
    component: RegisterComponent
  },
  {
    path: 'ForgotPassword',
    component: ForgotPasswordComponent
  },
  {
    path: 'NotFound',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
