import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionDetailSerice {


  private themeChanged = new Subject<any>();

  constructor() { }


  onthemeChange() {
    this.themeChanged.next();
  }

  getThemeInfo(): Observable<any> {
    return this.themeChanged.asObservable();
  }

  getAdminConfiguredColor() {
    const color = localStorage.getItem("color");
    if (color && color.length > 0) {
      return color;
    } else {
      return "#03344E"; // default color
    }
  }
}
