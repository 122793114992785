<div class="icon-container">
    <div class="icon">
      <img
        [src]="iconUrl"
      />
    </div>
    <div class="progress-step-heading-container">
      <h3 class="progress-step-heading">{{stepHeading}}</h3>
      <h4 class="step-time">{{stepCompletionStatus}}</h4>
    </div>
</div>
