// export const Us_State_prov =
//     ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY '];


export const Us_State_prov = [
    { value: 'AL', displayValue: 'Alabama' },
    { value: 'AK', displayValue: 'Alaska' },
    { value: 'AZ', displayValue: 'Arizona' },
    { value: 'AR', displayValue: 'Arkansas' },
    { value: 'CA', displayValue: 'California' },
    { value: 'CO', displayValue: 'Colorado' },
    { value: 'CT', displayValue: 'Connecticut' },
    { value: 'DE', displayValue: 'Delaware' },
    { value: 'FL', displayValue: 'Florida' },
    { value: 'GA', displayValue: 'Georgia' },
    { value: 'HI', displayValue: 'Hawaii' },
    { value: 'ID', displayValue: 'Idaho' },
    { value: 'IL', displayValue: 'Illinois' },
    { value: 'IN', displayValue: 'Indiana' },
    { value: 'IA', displayValue: 'Iowa' },
    { value: 'KS', displayValue: 'Kansas' },
    { value: 'KY', displayValue: 'Kentucky' },
    { value: 'LA', displayValue: 'Louisiana' },
    { value: 'ME', displayValue: 'Maine' },
    { value: 'MD', displayValue: 'Maryland' },
    { value: 'MA', displayValue: 'Massachusetts' },
    { value: 'MI', displayValue: 'Michigan' },
    { value: 'MN', displayValue: 'Minnesota' },
    { value: 'MS', displayValue: 'Mississippi' },
    { value: 'MO', displayValue: 'Missouri' },
    { value: 'MT', displayValue: 'Montana' },
    { value: 'NE', displayValue: 'Nebraska' },
    { value: 'NV', displayValue: 'Nevada' },
    { value: 'NH', displayValue: 'New Hampshire' },
    { value: 'NJ', displayValue: 'New Jersey' },
    { value: 'NM', displayValue: 'New Mexico' },
    { value: 'NY', displayValue: 'New York' },
    { value: 'NC', displayValue: 'North Carolina' },
    { value: 'ND', displayValue: 'North Dakota' },
    { value: 'OH', displayValue: 'Ohio' },
    { value: 'OK', displayValue: 'Oklahoma' },
    { value: 'OR', displayValue: 'Oregon' },
    { value: 'PA', displayValue: 'Pennsylvania' },
    { value: 'RI', displayValue: 'Rhode Island' },
    { value: 'SC', displayValue: 'South Carolina' },
    { value: 'SD', displayValue: 'South Dakota' },
    { value: 'TN', displayValue: 'Tennessee' },
    { value: 'TX', displayValue: 'Texas' },
    { value: 'UT', displayValue: 'Utah' },
    { value: 'VT', displayValue: 'Vermont' },
    { value: 'VA', displayValue: 'Virginia' },
    { value: 'WA', displayValue: 'Washington' },
    { value: 'WV', displayValue: 'West Virginia' },
    { value: 'WI', displayValue: 'Wisconsin' },
    { value: 'WY', displayValue: 'Wyoming' },
];

export const Ca_State_prov = [
    { displayValue: 'Alberta', value: 'AB' },
    { displayValue: 'British Columbia', value: 'BC' },
    { displayValue: 'Manitoba', value: 'MB' },
    { displayValue: 'New Brunswick', value: 'NB' },
    { displayValue: 'Newfoundland and Labrador', value: 'NL' },
    { displayValue: 'Northwest Territories', value: 'NT' },
    { displayValue: 'Nova Scotia', value: 'NS' },
    { displayValue: 'Nunavut', value: 'NU' },
    { displayValue: 'Ontario', value: 'ON' },
    { displayValue: 'Prince Edward Island', value: 'PE' },
    { displayValue: 'Quebec', value: 'QC' },
    { displayValue: 'Saskatchewan', value: 'SK' },
    { displayValue: 'Yukon', value: 'YT' }
];

export const Transaction_Types = [
    { value: 1, displayValue: "Invoice" },
    { value: -1, displayValue: "Voided Invoice" },
    { value: 2, displayValue: "check" },
    { value: -2, displayValue: "Voided Check" },
    { value: 3, displayValue: "Electronic Payment" },
    { value: -3, displayValue: "Voided Electronic Payment" },
    { value: 4, displayValue: "Balance Transfer To Account" },
    { value: -4, displayValue: "Voided Balance Transfer To Account" },
    { value: 5, displayValue: "Balance Transfer From Account" },
    { value: -5, displayValue: "Voided Balance Transfer From Account" },
    { value: 6, displayValue: "Write Off" },
    { value: -6, displayValue: "Voided Write Off" },
    { value: 8, displayValue: "Refund Request" },
    { value: -8, displayValue: "Voided Refund Request" },
    { value: 9, displayValue: "Balance Forward" },
    { value: -9, displayValue: "Voided Balance Forward" },
    { value: 10, displayValue: "Cash Credit" },
    { value: -10, displayValue: "Voided Cash Credit" },
    { value: 11, displayValue: "Credit From Overpayment" },
    { value: -11, displayValue: "Voided Credit From Overpayment" },
    { value: 12, displayValue: "Electronic Refund Disbursement" },
    { value: -12, displayValue: "Voided Electronic Refund Disbursement" },
    { value: 13, displayValue: "Check Refund Disbursementt" },
    { value: -13, displayValue: "Voided Check Refund Disbursement" },
    { value: 14, displayValue: "External charge" },
    { value: -14, displayValue: "Voided External Charge" },
    { value: 15, displayValue: "Dunning Charge" },
    { value: -15, displayValue: "Voided Dunning Charge" },
    { value: 16, displayValue: "reinstated Charge transaction" },
    { value: -16, displayValue: "voided reinstated Charge transaction" },
    { value: 17, displayValue: "reinstated Payment transaction" },
    { value: -17, displayValue: "voided reinstated payment transaction" },
    { value: 18, displayValue: "Negative Balance Forward" },
    { value: -18, displayValue: "Voided Negative Balance Forward" },
    { value: 19, displayValue: "3rd party payment" },
    { value: -19, displayValue: "voided 3rd party payment" },
    { value: 20, displayValue: "3rd party payment refund" },
    { value: -20, displayValue: "voided 3rd party payment refund" },
    { value: 21, displayValue: "Invoice charge line item" },
    { value: -21, displayValue: "Voided Invoice charge line item" },
    { value: 22, displayValue: "Invoice credit line item" },
    { value: -22, displayValue: "Voided Invoice credit line item" },
    { value: 24, displayValue: "Credit memo line" },
    { value: -24, displayValue: "Voided credit memo line" },
];

export const PlanStatus = [
    { value: -3, displayValue: "Terminated" },
    { value: -2, displayValue: "Cancelled" },
    { value: -1, displayValue: "Suspended" },
    { value: 0, displayValue: "Inactive" },
    { value: 1, displayValue: "Active" },
    { value: 2, displayValue: "Pending Cancellation" },
    { value: 3, displayValue: "Pending Termination" },
    { value: 31, displayValue: "Pending Installation" },
    { value: 32, displayValue: "Pending Activation" },
    { value: 41, displayValue: "Trial" },
    { value: 61, displayValue: "Active Non-billable" },
]