import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  questions = [
    { displayValue: 'Favourite pet name?', value: 'Favourite pet name?' },
    { displayValue: 'School name?', value: 'School name?'},
    { displayValue: 'Favourite place to go?', value: 'Favourite place to go?' }
  ];
  public defaultSelected ='Favourite pet name?'; 
  constructor() { }

  ngOnInit(): void {
  }

}
