<div class="header" [ngStyle]="{background: color ? color  : '#FFFFFF'}">
    <div class="hamburger-title-container">
        <div class="hamburger-btn-container" *ngIf="showSideBarNavigation">
            <button *ngIf="opened" class="hamburger-btn" (click)="this.toggleSideNav()" title="Close Menu">
                <mat-icon class="hamburger">close</mat-icon>
            </button>
            <button *ngIf="!opened" class="hamburger-btn" (click)="this.toggleSideNav()" title="Open Menu">
                <mat-icon class="hamburger">menu</mat-icon>
            </button>
        </div>
        <div class="project-title-container">
            <h3 class="portal-type">{{projectTitle}}</h3>
        </div>
    </div>
    <!-- <h3 class="portal-type">{{projectTitle}}</h3> -->
    <div class="honda-icon">
        <img src="{{logoFilePath}}">
    </div>
    <div class="loggedIn-user-container">
        <div class="loggedIn-user">
            <h4 class="message">Welcome, </h4>
            <h4 class="user" [matMenuTriggerFor]="menu">{{userName && userName}}</h4>
            <!-- #docregion mat-menu-trigger-for -->
            <!-- <button mat-button [matMenuTriggerFor]="menu">username</button> -->
            <!-- #enddocregion mat-menu-trigger-for -->
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="logOut()">Logout</button>

            </mat-menu>
        </div>
        <!-- <img src="../../assets/Icons/Polygon 1.png" class="polygon"> -->
        <mat-icon class="arrow_drop_down">arrow_drop_down</mat-icon>
    </div>
</div>