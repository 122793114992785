<div class="main-container">
    <div class="Register-screen-left-container">
        <img src="../../assets/register-background.jpg"/>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat</p>
    </div>
    <div class="Register-screen">
        <div class="title-container">
            <h2 class="heading">Join Us</h2>
            <p class="sub-heading">Create a new account</p>
        </div>
        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="Register-form">
            <!-- <div *ngIf="!registerForm.get('firstName')?.valid && registerForm.get('firstName')?.touched
            || !registerForm.get('lastName')?.valid && registerForm.get('lastName')?.touched"
            class="Error"> -->
            <div *ngIf="checkFormValidation()" class="Error full-width">
                *Please fill required fields!
            </div>
            <mat-form-field class="custom-mat-input input-field" appearance="outline">
                <input matInput placeholder="First name" class="input-text" formControlName="first_name">
            </mat-form-field>
            <mat-form-field class="custom-mat-input input-field" appearance="outline">
                <input matInput placeholder="Last name" class="input-text" formControlName="last_name">
            </mat-form-field>
            <!-- <mat-form-field class="custom-mat-input input-field" appearance="outline">
                <input matInput placeholder="User id" class="input-text" formControlName="userid">
            </mat-form-field> -->
    
            <mat-form-field class="custom-mat-input input-field" appearance="outline">
                <input matInput placeholder="Email/Userid" class="input-text" formControlName="email" type="email">
            </mat-form-field>
        
            <mat-form-field class="custom-mat-input input-field" appearance="outline">
                <input matInput placeholder="Password" type="password" class="input-text" formControlName="password">
            </mat-form-field>
            
            <div style="width:100%">
                <mat-form-field class="custom-mat-input input-field" appearance="outline">
                    <input matInput placeholder="Phone" class="input-text" formControlName="phone">
                </mat-form-field>
            </div>

            <mat-form-field class="custom-mat-input input-field" appearance="outline">
                <input matInput placeholder="Address Line 1" class="input-text" formControlName="address1">
            </mat-form-field>
            
            <mat-form-field class="custom-mat-input input-field" appearance="outline">
                <input matInput placeholder="Address Line 2" class="input-text" formControlName="address2">
            </mat-form-field>
            
            <mat-form-field class="custom-mat-input input-field" appearance="outline">
                <input matInput placeholder="City" class="input-text" formControlName="city">
            </mat-form-field>

            <mat-form-field class="custom-mat-input input-field left-margin" appearance="outline">
                <mat-select formControlName="state_prov">
                    <mat-option *ngFor="let state of stateProvDropdown" [value]="state.value" class="input-text">
                        {{state.displayValue}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

         

            <mat-form-field class="custom-mat-input input-field" appearance="outline">
                <input matInput placeholder="Zip code" class="input-text" formControlName="postal_cd">
            </mat-form-field>


            
            <mat-form-field class="custom-mat-input input-field" appearance="outline">
                <mat-select formControlName="country_cd"  (selectionChange)="this.onCountryChange($event)">
                    <mat-option *ngFor="let contoury of countries" [value]="contoury.value" class="input-text">
                        {{contoury.displayValue}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="custom-mat-input full-width field margin-right" appearance="outline">
                <mat-select formControlName="secret_question">
                    <mat-option *ngFor="let q of questions" [value]="q.value">
                        {{q.displayValue}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="custom-mat-input full-width  margin-right" appearance="outline">
                <input matInput placeholder="Secret Answer" class="input-text" formControlName="secret_question_answer">
            </mat-form-field>
        
            <div class="btn-container">
                <button mat-stroked-button color="primary" class="cancel-button" [routerLink]="'/Login'">Login</button>
                <button mat-stroked-button color="primary" class="confirm-button" type="submit">Register</button>
            </div>
        </form>
    </div>
</div>