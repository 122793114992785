import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-reasons-list',
  templateUrl: './reasons-list.component.html',
  styleUrls: ['./reasons-list.component.scss']
})
export class ReasonsListComponent implements OnInit {

  constructor() { }

  @Input() reasons: string[] = ['I No Longer Use This Product', 'I Want To Try A Different Product', 
  'I Already Have More Than I Need','Lack Of Value In The Service', 'Other'];
  selectedReason:string='I No Longer Use This Product';

  form:FormGroup=new FormGroup( {
    reason:new FormControl(this.selectedReason,Validators.required),
    explanation:new FormControl("")
  });

  ngOnInit(): void {
  }
  reasonChanged(){
      if(this.form.controls.reason.value=="Other"){
          this.form.controls['explanation'].setValidators(Validators.compose([Validators.required,Validators.minLength(5)]));
      }
      else{
        this.form.controls['explanation'].setValidators(null);
      }
      this.form.controls['explanation'].updateValueAndValidity();
    }
}
