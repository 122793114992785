<!-- <div style="background-color: aquamarine;"> <a mat-flat-button color="primary" style="margin: 1em;" [routerLink]="'/DealerPortal'">Open Dealer Portal</a>
<a mat-flat-button color="primary" style="margin: 1em;" [routerLink]="'/CustomerPortal'">Open CS Portal</a>
<a mat-flat-button color="primary" style="margin: 1em;" [routerLink]="'/CustomerPortal/manage-subscription'">Open Package Details</a>
<a mat-flat-button color="primary" style="margin: 1em;" (click)="showLoader()">Show Loader</a>
<a mat-flat-button color="primary" style="margin: 1em;" [routerLink]="'/NotFound'">404 - Not Found</a>
<a mat-flat-button color="primary" style="margin: 1em;" [routerLink]="'/Login'">Login</a>
</div> -->

<mat-sidenav-container>
  <mat-sidenav mode="side" [(opened)]="opened">
    
  <mat-toolbar color="primary">Admin Menu</mat-toolbar>
  <mat-nav-list>
    <a mat-list-item (click)="this.navigate('/Admin/SearchAccount')">Search Account</a>
    <a mat-list-item (click)="this.navigate('/Admin/ChangeConfig')">Change Configuration </a>
  </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div>
      
      
      <!-- <div *ngIf="showSideBarNavigation">
      <button *ngIf="opened" class="hamburger-btn" (click)="opened=!opened"><mat-icon>close</mat-icon></button>
      <button *ngIf="!opened" class="hamburger-btn" (click)="opened=!opened"><mat-icon>menu</mat-icon></button>
    </div> -->
      <router-outlet></router-outlet>
    </div>
   
  </mat-sidenav-content>
</mat-sidenav-container>
<div class="loader" [ngStyle]="{'display':loader?'block':'none'}">
</div>
<app-loader></app-loader>
