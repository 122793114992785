import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChangeSubscription } from './register-change-subscription.model';
import { RegisterChangeSubscriptionService } from './register-change-subscription.service';

@Component({
  selector: 'app-register-change-subscription',
  templateUrl: './register-change-subscription.component.html',
  styleUrls: ['./register-change-subscription.component.scss']
})
export class RegisterChangeSubscriptionComponent implements OnInit {

  constructor(private route: ActivatedRoute, private changeSubscriptionService: RegisterChangeSubscriptionService) { }
  public packages : ChangeSubscription [] = [];
  ngOnInit(): void {
    this.initParams();
    this.changeSubscriptionService.Get().subscribe((response)=>{
      console.log(response);
      if(response){
        this.packages = response;
      }
      
    });
  }

  initParams(){
    this.route.queryParamMap.subscribe((snap: any) => {
      console.log(snap);
    });
  }

}
