import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { Account } from 'src/app/register/register.model';


@Injectable({
  providedIn: 'root'
})
export class PackageDetailsService {
  private userInfo = new Subject<any>();  

  constructor(protected dataService: DataService) { }

  sendUserName(account: Account) {  
    this.userInfo.next(account);  
  }  

  getUserName(): Observable<any> {  
    return this.userInfo.asObservable();  
  }  

  GetAcctDetails(data: any): Observable<any> {
    return this.dataService.post<Account>(`SubscriptionBilling/GetAcctDetails`, data);
  }

  GetAcctPlans(data: any): Observable<any> {
    return this.dataService.post<any>(`SubscriptionBilling/GetAcctPlans`, data);
  }

  GetAcctPaymentDetail(data: any): Observable<any> {
    return this.dataService.post<any>(`Payment/GetAcctPaymentDetail`, data);
  }

  GetAcctComments(data: any): Observable<any> {
    return this.dataService.post<any>(`SubscriptionBilling/GetAcctComments`, data);
  }

  GetAcctContracts(data: any): Observable<any> {
    return this.dataService.post<any>(`SubscriptionBilling/GetAcctContracts`, data);
  }

  AddComment(data: any): Observable<any> {
    return this.dataService.post<any>(`SubscriptionBilling/userComment`, data);
  }

  GetTransactionHistory(data: any): Observable<any> {
    return this.dataService.post<any>(`SubscriptionBilling/getTransactionHistory`, data);
  }

  CancelSubscription(data: any): Observable<any> {
    return this.dataService.post<any>(`SubscriptionBilling/cancelSubcription`, data);
  }
  
  EnableCancelSubscription(data: any): Observable<any> {
    return this.dataService.post<any>(`SubscriptionBilling/enableCancelSubscription`, data);
  }
  
  getCompensationReasons(): Observable<any> {
    return this.dataService.get<any>(`Payment/getAllReasonCodes`);
  }
}
