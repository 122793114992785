import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";
import { DataService } from "./core/services/data.service";
import { Account } from "./register/register.model";

@Injectable({
    providedIn: 'root'
})
export class AppService {
    private subNavInfo = new Subject<any>();
    private openNavigation : boolean = false;

    constructor(protected dataService: DataService) { }

    toggleSubNavigation(data:any) {
        
        if(data!=undefined){
            this.openNavigation = data;
        }

        this.subNavInfo.next(data);
    }

    getSubNavInfo(): Observable<any> {
        return this.subNavInfo.asObservable();
    }

    getNavigationState(){
        return this.openNavigation;
    }
}
