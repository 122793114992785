import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotFoundComponent } from './not-found/not-found.component'
import { SharedModule } from './shared/shared.module';
import { RegisterComponent } from './register/register.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { DataService } from './core/services/data.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiInterceptor } from './core/Interceptors/api.interceptor';
import { AuthService } from './core/auth/auth.service';
import { AuthGuard } from './core/auth/auth-guard.service';
import { AdminGuard } from './core/auth/admin-guard.service';
import { LoaderComponent } from './loader/loader.component';
import { LoaderInterceptor } from './core/Interceptors/loader.interceptor';
import { RegisterChangeSubscriptionPlanComponent } from './packageSelection/change-subscription-plan/register-change-subscription-plan.component';
import { RegisterPlanCardComponent } from './packageSelection/plan-card/register-plan-card.component';
import { CommonModule } from '@angular/common';
import { RegisterPackageCardComponent } from './packageSelection/package-card/resigter-package-card.component';
import { RegisterChangeSubscriptionComponent } from './packageSelection/change-subscription/register-change-subscription.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    RegisterComponent,
    LoaderComponent,
    RegisterChangeSubscriptionPlanComponent,
    RegisterPlanCardComponent,
    RegisterPackageCardComponent,
    RegisterChangeSubscriptionComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    HttpClientModule,
    MatDialogModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatButtonModule,
    SharedModule,
    ReactiveFormsModule,
    RouterModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    NgSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatCardModule,
    MatRadioModule,
    MatListModule,
    MatIconModule,
    MatSidenavModule,
    MatToolbarModule
  ],
  exports: [HttpClientModule],

  providers: [
    DataService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    AuthService,
    AuthGuard,
    AdminGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true

    }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
