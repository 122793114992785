import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from '../core/services/data.service';
import { Register } from './register.model';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(protected dataService: DataService) { }

  Post(data: any): Observable<any> {
    return this.dataService.post<Register>(`User/RegisterAccount`, data);
  }
}
