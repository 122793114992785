import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ChangeSubscription } from '../change-subscription/register-change-subscription.model';

@Component({
  selector: 'app-resigter-package-card',
  templateUrl: './resigter-package-card.component.html',
  styleUrls: ['./resigter-package-card.component.scss']
})
export class RegisterPackageCardComponent implements OnInit {

  selectedPlan: string;

  availableSubPackages = [] as any;
  selectedSubPackage: string;
  constructor(private router: Router) { }

  @Input() public package: ChangeSubscription;
  @Input() packageTitle: string = "Premium Package";
  @Input() packageDescription: string = "Remotely start, lock/unlock, find your car and more! HondaLink provides convenience at a touch with 8 additional features.";
  @Input() packagePrice: number = 20.00;
  @Input() packageDetails: string[] = ['Advanced prioritisation with Matrix', 'Advanced prioritisation with Matrix', 'Advanced prioritisation with Matrix', 'Advanced prioritisation with Matrix', 'Advanced prioritisation with Matrix'];


  ngOnInit(): void {
    if (this.package && this.package.plan_rate_schedules_details && this.package.plan_rate_schedules_details.length > 0) {

      for (const iterator of this.package.plan_rate_schedules_details) {
        const obj = { displayValue: iterator.schedule_name, value: iterator.schedule_no };
        this.availableSubPackages.push(obj);
      }

      // setting default value for subPackage selection
      this.selectedSubPackage = this.availableSubPackages[0].value;

    }
  }

  onPackageSelect(planNo: any) {
    if(planNo && this.selectedSubPackage){
      this.router.navigate(['PlanSelection'], { queryParams: { planNo: planNo, subPackage: this.selectedSubPackage } });
    }
  }

}
