import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dealer-popup-footer',
  templateUrl: './dealer-popup-footer.component.html',
  styleUrls: ['./dealer-popup-footer.component.scss']
})
export class DealerPopupFooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
