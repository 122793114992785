<div class="footer">
    <div class="car-owner-container">
        <div class="car-icon">
            <img src="../../../assets/car.svg">
        </div>
        <div class="owner-details">

            <h4 class="">Tara Owens</h4>
            <p>2020 Honda Accord Touring</p>
            <p>19UUB41F85GA0003488</p>
        </div>
    </div>

    <div class="package-details-container">
        <div class="info-icon">
            <img src="../../../assets/information.svg">
        </div>
        <div class="package-details">
            <h4>Telmatics Package</h4>
            <p>3 Month Complimentary</p>
            <p>Trial Enrollment</p>
        </div>

    </div>
</div>