import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from 'src/app/app.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { PackageDetailsService } from 'src/app/customer-care-portal/manage-subscription/package-details/package-details.service';
import { LoginService } from 'src/app/login-screen/login/login.service';
import { Logout } from 'src/app/login-screen/login/logout.model';
import { StatusDTO } from '../Models/Models';
import { StepCompletionStatusComponent } from '../step-completion-status/step-completion-status.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


  @Input() moduleName: string = 'Dealer Portal';
  @Input() username: string = 'Jane Doe';

  projectTitle: any;
  logoFilePath: any;
  color: any;
  userId: any;
  userName: string;
  showSideBarNavigation: boolean = false;
  opened: boolean = false;
  constructor(
    private authService: AuthService,
    private loginService: LoginService,
    private dialog: MatDialog,
    private packageDetailService: PackageDetailsService,
    private appService: AppService) { }

  ngOnInit(): void {
    const color = localStorage.getItem("color");
    const projectTitle = localStorage.getItem("projectTitle");
    const logoFilePath = localStorage.getItem("logoFilePath");
    const userId = localStorage.getItem("user_id");
    const userFirstName = localStorage.getItem("first_name");
    const userLastName = localStorage.getItem("last_name");
    const isadmin = localStorage.getItem("is_admin");
    const impersonate = localStorage.getItem("impersonate"); // we have to clear this at some point

    this.showSideBarNavigation = isadmin && isadmin === "yes" ? true : false;

    if (isadmin && isadmin === "yes" && (!impersonate || impersonate != "yes")) {
      this.userName = "Admin";
    } else {
      this.userName = userFirstName && userLastName && userFirstName != "null" && userLastName != "null" ? userFirstName + ' ' + userLastName : userId!;
    }

    this.subscribedUserInfo();
    this.subscribeNavState();

    this.projectTitle = projectTitle;
    this.logoFilePath = logoFilePath;
    this.color = color;
    this.userId = userId;
  }
  subscribeNavState() {
    const navState = this.appService.getNavigationState();
    if(navState !=undefined){
      this.opened = navState;
    }
  }

  logOut() {
    const sessionId = localStorage.getItem("session_id");
    const isadmin = localStorage.getItem("is_admin");
    const reqObj: Logout = {} as Logout;

    reqObj.is_admin = isadmin && isadmin === "yes" ? true : false;
    reqObj.session_id = sessionId!;

    this.loginService.Logout(reqObj).subscribe((result) => {
      if (result && result.errorCode === 0 && result.errorMessage === "OK") {
        this.loginService.removeAdminStorage({ admin: false });// removing 
        this.appService.toggleSubNavigation(false);// to force fully close the side navigation bar.
        this.authService.logout();
      }
      else if (result && result.errorCode === 1011) {
        this.authService.logout();
      } else {
        let statusData: StatusDTO = {
          title: 'Error',
          details: result.errorMessage,
          stepSuccessful: false
        }

        let ref = this.dialog.open(StepCompletionStatusComponent,
          { height: '48vh', width: '42vw', data: statusData });
      }

    });
  }

  subscribedUserInfo() {
    this.packageDetailService.getUserName().subscribe((res) => {
      if (res) {
        this.userName = res.first_name + ' ' + res.last_name;
      }
    });
  }

  toggleSideNav() {
    this.opened = !this.opened;
    this.subscribedSubNavigation(this.opened);
  }
  subscribedSubNavigation(opened: boolean) {
    this.appService.toggleSubNavigation(opened);
  }

}
