import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { StatusDTO } from 'src/app/shared/Models/Models';
import { StepCompletionStatusComponent } from 'src/app/shared/step-completion-status/step-completion-status.component';
import { ManageDiscountsComponent } from '../manage-discounts/manage-discounts.component';
import { Router } from "@angular/router"
import { ChangeSubscriptionPlanService } from './change-subscription-plan.service';
import { ChangeSubcriptionPlan } from './change-subscription-plan.model';

@Component({
  selector: 'app-manage-subscription-plan',
  templateUrl: './change-subscription-plan.component.html',
  styleUrls: ['./change-subscription-plan.component.scss']
})
export class ChangeSubscriptionPlanComponent implements OnInit {


  radioSelection: string = "1";
  private planNo: string;
  private subPackage: string;
  private existingMPinFo: string;
  private addNewFlag: string;
  constructor(private dialog: MatDialog, private route: ActivatedRoute, private router: Router, private changePlanService: ChangeSubscriptionPlanService) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((snap: any) => {
      console.log(snap);
      if (snap) {
        if ((!snap.params.planNo || snap.params.planNo && snap.params.planNo.length === 0) ||
          (!snap.params.subPackage || snap.params.subPackage && snap.params.subPackage.length === 0) || 
          (!snap.params.mpInfo || snap.params.mpInfo && snap.params.mpInfo.length === 0) ||
          (!snap.params.svflag || snap.params.svflag && snap.params.svflag.length === 0)
          ) {
          this.router.navigate(['/CustomerPortal/manage-subscription']);
        }

        this.planNo = snap.params.planNo;
        this.subPackage = snap.params.subPackage;
        this.existingMPinFo = snap.params.mpInfo;
        this.addNewFlag = snap.params.svflag;
      }


    });
  }


  ManageDiscountsClick() {
    this.dialog.open(ManageDiscountsComponent,
      { height: '95vh', width: '60vw' })
  }

  updateSubscription() {
    console.log("this.planNo", this.planNo);
    console.log("this.subPackage", this.subPackage);
    console.log("this.addNewFlag", this.addNewFlag);
    debugger;
    const clientAcctId = localStorage.getItem("client_acct_id");
    let reqObj = new ChangeSubcriptionPlan();
    reqObj.client_acct_id = clientAcctId!;
    reqObj.plan_no = this.planNo;
    reqObj.schedule_no = this.subPackage;
    reqObj.existing_master_plan_instance_no  = this.existingMPinFo;

    this.changePlanService.AddOrChangeSubscription(reqObj, this.addNewFlag).subscribe((res) => {
      if (res && res.error_msg === "OK" && res.error_code === 0) {
        
        let statusData: StatusDTO = {
          title: this.addNewFlag && this.addNewFlag === "1" ? 'Package Subscription Added!' : 'Package Subscription Update!',
          details: this.addNewFlag && this.addNewFlag === "1" ? 'Your packge has been successfully added. ' : 'Your packge has been successfully updated',
          stepSuccessful: true
        };
        
        let ref = this.dialog.open(StepCompletionStatusComponent,
          { height: '48vh', width: '42vw', data: statusData });

        ref.afterClosed().subscribe((x) => {
          this.router.navigate(['/CustomerPortal/manage-subscription']);
        });

      } else {

        let statusData: StatusDTO = {
          title: 'Package Subscription Update Failed!',
          details: res.error_msg,
          stepSuccessful: false
        }
        let ref = this.dialog.open(StepCompletionStatusComponent,
          { height: '48vh', width: '42vw', data: statusData });
      }
      
    });


  }
}
