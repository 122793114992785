import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CancelDTO } from '../../Models/Models';

@Component({
  selector: 'app-cancel-popup',
  templateUrl: './cancel-popup.component.html',
  styleUrls: ['./cancel-popup.component.scss']
})
export class CancelPopupComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: CancelDTO, public matDialogRef: MatDialogRef<CancelPopupComponent>) { }

  @Input() reasons: string[] = ['I No Longer Use This Product', 'I Want To Try A Different Product', 
  'I Already Have More Than I Need','Lack Of Value In The Service', 'Other'];
  selectedReason:string='I No Longer Use This Product';

  cancelForm:FormGroup=new FormGroup( {
    reason:new FormControl(this.selectedReason,Validators.required),
    explanation:new FormControl("")
  });

  ngOnInit(): void {
  }
  
  reasonChanged(){
    if(this.cancelForm.controls.reason.value=="Other"){
        this.cancelForm.controls['explanation'].setValidators(Validators.compose([Validators.required,Validators.minLength(5)]));
    }
    else{
      this.cancelForm.controls['explanation'].setValidators(null);
    }
    this.cancelForm.controls['explanation'].updateValueAndValidity();
  }

  

  onClickClose(){
    this.matDialogRef.close();
  }

  onSubmitClick() {
    const cancelForm = this.cancelForm.value;
    if(this.cancelForm.valid){
      let returnObj = cancelForm.reason;
      if(cancelForm.explanation && cancelForm.explanation.length > 0){
        returnObj = returnObj + ' - ' + cancelForm.explanation;
      }
     
      return this.matDialogRef.close(returnObj);
    }
  }
}
