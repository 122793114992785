
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import { throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, take, filter, finalize, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import {default as baseSetting} from '../../../environments/base-settings.json'

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let baseUrl = 'http://localhost:55003/';
    if(baseSetting && baseSetting.baseUrl?.length > 0){
      baseUrl = baseSetting.baseUrl;
    }
    const apiReq = req.clone({ url: baseUrl + req.url });
    return next.handle(apiReq);
  }
}
