import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Coupons, Promo, StatusDTO } from 'src/app/shared/Models/Models';
import { StepCompletionStatusComponent } from 'src/app/shared/step-completion-status/step-completion-status.component';
import { ManageDiscountsService } from './manage-discounts.service';

@Component({
    selector: 'app-manage-discounts',
    templateUrl: './manage-discounts.component.html',
    styleUrls: ['./manage-discounts.component.scss']
})
export class ManageDiscountsComponent implements OnInit {

    totalCoupons: Promo[] = [];
    acctCoupons: Promo[] = [];
    searchCoupons: string = "";
    filteredCoupons : Promo [] = [];

    constructor(private _manageDiscountService: ManageDiscountsService, private dialog: MatDialog) { }

    ngOnInit(): void {
        this.getAccountCoupons();
    }

    getAccountCoupons() {
        const clientAcctId = localStorage.getItem("client_acct_id");
        this._manageDiscountService.GetAcctCoupons({ client_acct_id: clientAcctId }).subscribe((res) => {
            if (res && res.error_code === 0 && res.error_msg === "OK") {
                // this.acctCoupons = res.coupons_detail;
                for (const iterator of res.coupons_detail) {
                    let obj: Promo = {
                        name: "",
                        info: "",
                        discount: 0,
                        selected: false,
                        coupon_code: ""
                    }
                    obj.name = "Promo";
                    obj.info = iterator.description;
                    obj.coupon_code = iterator.out_coupon_cd;
                    this.acctCoupons.push(obj);
                }
            }

            this.getPromos();
        });
    }

    getPromos() {
        this._manageDiscountService.GetCoupons().subscribe((res) => {
            if (res && res.error_code === 0 && res.error_msg === "OK") {
                for (const iterator of res.coupons) {
                    let obj: Promo = {
                        name: "",
                        info: "",
                        discount: 0,
                        selected: false,
                        coupon_code: ""
                    }
                    obj.name = "Promo";
                    obj.info = iterator.coupon_desc;
                    obj.coupon_code = iterator.coupon_cd;

                    const existed = this.acctCoupons.filter(x =>
                        x.coupon_code === obj.coupon_code
                    );
                    if (existed && existed.length > 0) {
                        obj.selected = true;
                    }

                    this.totalCoupons.push(obj);
                }
                this.filteredCoupons = this.totalCoupons;
            }
        });
    }

    promoUpdate(promo: Promo, _event: any) {
        // promo.selected = !promo.selected;

        const operation = !promo.selected; // confirming wheter it is removed or add operation

        const reqObj: UpdatePromo = {} as UpdatePromo;
        const clientAcctId = localStorage.getItem("client_acct_id");
        reqObj.client_acct_id = clientAcctId!;
        reqObj.coupon_code = promo.coupon_code;

        if (operation) {
            // we are Adding this coupon
            debugger;
            this._manageDiscountService.ApplyCoupon(reqObj).subscribe((res) => {
                console.log(reqObj);
                if (res && res.error_code === 0 && res.error_msg === "OK") {

                    for (const iterator of this.totalCoupons) {
                        if (iterator.coupon_code === reqObj.coupon_code) {
                            iterator.selected = true;
                        }
                    }


                    let statusData: StatusDTO = {
                        title: "Promo Added",
                        details: "Select Promo has been successfully added",
                        stepSuccessful: true
                    }

                    let ref = this.dialog.open(StepCompletionStatusComponent,
                        { height: '80vh', width: '40vw', data: statusData });
                } else {
                    let statusData: StatusDTO = {
                        title: "Promo Failed to be Added.!",
                        details: res.error_msg,
                        stepSuccessful: false
                    }

                    let ref = this.dialog.open(StepCompletionStatusComponent,
                        { height: '80vh', width: '40vw', data: statusData });
                }

            });
        } else {
            // we are Removing this coupon
            this._manageDiscountService.RemoveCoupon(reqObj).subscribe((res) => {
                if (res && res.error_code === 0 && res.error_msg === "OK") {

                    for (const iterator of this.totalCoupons) {
                        if (iterator.coupon_code === reqObj.coupon_code) {
                            iterator.selected = false;
                        }
                    }


                    let statusData: StatusDTO = {
                        title: "Coupon Removed.!",
                        details: "Select Promo has been successfully removed",
                        stepSuccessful: true
                    }

                    let ref = this.dialog.open(StepCompletionStatusComponent,
                        { height: '80vh', width: '40vw', data: statusData });
                } else {
                    let statusData: StatusDTO = {
                        title: "Promo Failed to be Removed.!",
                        details: res.error_msg,
                        stepSuccessful: false
                    }

                    let ref = this.dialog.open(StepCompletionStatusComponent,
                        { height: '80vh', width: '40vw', data: statusData });
                }
            });
        }
    }

    searchCoupon(){
        if(this.searchCoupons && this.searchCoupons.length > 0){
            const filtered = this.totalCoupons.filter(x =>
                x.coupon_code.includes(this.searchCoupons) && x.selected === false
            );
            this.filteredCoupons = filtered;

        } else {
            this.filteredCoupons = this.totalCoupons;
        }
    }
}

export interface UpdatePromo {
    client_acct_id: string;
    coupon_code: string;
}

export interface CouponDetails {
    coupon_scope: string;
    currency_cd: string;
    description: string;
    out_coupon_cd: string;
    status_ind: string;
}