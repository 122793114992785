import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { ChangeSubcriptionPlan } from './change-subscription-plan.model';

@Injectable({
  providedIn: 'root'
})
export class ChangeSubscriptionPlanService {

  constructor(protected dataService: DataService) { }

  AddOrChangeSubscription(data:ChangeSubcriptionPlan, addOrChange:string): Observable<any> {
    if(!addOrChange || addOrChange != "1"){
      return this.dataService.post<any>(`SubscriptionBilling/updateSubcription`, data);
    } else {
      return this.dataService.post<any>(`SubscriptionBilling/addSubcription`, data);
    }
    
  }
}
