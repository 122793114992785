<div class="close-container">
    <img src="../assets/close_icon.svg" (click)="onClickClose()" class="close">
</div>

<div class="status-icon">
    <img src="../../../../assets/error.svg">
</div>

<h2 class="status">{{data.title}}</h2>
<p class="status-details">{{data.details}}</p>

<h2 class="sub-heading">Please select a reason</h2>
<!-- <app-reasons-list></app-reasons-list> -->
<form [formGroup]="cancelForm" (ngSubmit)="onSubmitClick()">
    <mat-radio-group formControlName="reason" (change)="reasonChanged()" class="package-details">
        <div class="reason-list">
            <mat-radio-button *ngFor="let reason of reasons" [value]="reason" color="primary" class="reason-text">
                {{reason}}
            </mat-radio-button>
        </div>
    </mat-radio-group>

    <h2 class="sub-heading">Details</h2>
    <mat-form-field appearance="outline" style="width: 100%;">
        <mat-label class="label-text">Please provide furthur details about the reason</mat-label>
        <textarea [required]="cancelForm.controls['reason'].value=='Other'" matInput formControlName="explanation"></textarea>
    </mat-form-field>

    <div class="btn-container">
        <button mat-stroked-button class="cancel-button" (click)="onClickClose()">Cancel</button>
        <button mat-stroked-button class="next-button" type="submit" [disabled]="!cancelForm.valid">Confirm</button>
    </div>
</form>

