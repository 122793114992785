import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()

export class AuthService {
  loggedin = false;
  constructor(private router: Router) {

  }

  isAuthenticated() {
    const sessionId = localStorage.getItem("session_id");
    let loggedIn = false;
    if (sessionId && sessionId.length > 0) {
      loggedIn = true;
      this.login();
    }

    return this.loggedin;
  }

  isUserAdmin() {
    const isAdmin = localStorage.getItem("is_admin");
    return isAdmin ? true : false;
  }
  login() {
    this.loggedin = true;
  }

  logout() {
    this.loggedin = false;
    localStorage.removeItem("session_id");
    localStorage.removeItem("is_admin");
    localStorage.removeItem("user_id");
    localStorage.removeItem("client_acct_id");
    localStorage.removeItem("first_name");
    localStorage.removeItem("last_name");
    localStorage.removeItem("email");
    localStorage.removeItem("impersonate"); 

    // localStorage.clear();
    this.router.navigate(["Login"]);
  }

}
