import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { Account } from 'src/app/register/register.model';


@Injectable({
  providedIn: 'root'
})
export class ManageDiscountsService {

  constructor(protected dataService: DataService) { }

  GetCoupons(): Observable<any> {
    return this.dataService.get<any>(`SubscriptionBilling/GetCoupons`);
  }

  GetAcctCoupons(data: any): Observable<any> {
    return this.dataService.post<any>(`SubscriptionBilling/GetAccountCoupons`, data);
  }

  ApplyCoupon(data: any): Observable<any> {
    return this.dataService.post<any>(`SubscriptionBilling/ApplyCoupon`, data);
  }

  RemoveCoupon(data: any): Observable<any> {
    return this.dataService.post<any>(`SubscriptionBilling/RemoveCoupon`, data);
  }

}
