<mat-card class="package-card">
    <mat-card-content>

        <h2 class="package-title">{{package.planName}}</h2>
        <!-- <p class="package-desc">{{package.planDescription}}</p> -->

        <!-- <div *ngFor="let detail of packageDetails" class="package-details">
            <span class="eclipe-icon"><img src="../../../assets/Ellipse.png"></span>
            <span class="package-details-text">{{detail}}</span>
        </div> -->
        <div class="package-details">
            <span class="eclipe-icon"><img src="../../../assets/Ellipse.png"></span>
            <span class="package-details-text" [innerHtml]="package.planDescription"></span>
        </div>

        <div class="price-container">
            <h3 class="price-starting price-starting-heading">Price</h3>
            <!-- <h3 class="price-starting price-starting-price">${{packagePrice}}/month</h3> -->
            <!-- <h3 class="price-starting price-starting-price">{{package.planDescription}}</h3> -->
            <mat-radio-group aria-labelledby="package-radio-group-label" class="package-radio-group"
                [(ngModel)]="selectedSubPackage">
                <mat-radio-button class="package-radio-button" *ngFor="let pack of availableSubPackages" [value]="pack.value">
                    {{pack.displayValue}}
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </mat-card-content>
    <mat-card-actions>
        <div class="btn-container">
            <!-- <button class="btn button-text" [routerLink]="['../PlanSelection','TestPackageSelected']">Select Package</button> -->
            <!-- [routerLink]="['../PlanSelection']"[queryParams]="{ planNo: package.planNo}"  -->
            <button class="btn button-text" 
            (click)="onPackageSelect(package.planNo)">Select Package</button>
        </div>
    </mat-card-actions>
</mat-card>