<div class="card login-screen">
    <div class="card-header">
        Forgot Password
    </div>
    <div class="card-data-container">
        <div class="card-data-main-container">
            <form >
                <mat-form-field class="custom-mat-input full-width" appearance="outline">
                    <mat-select [(value)]="defaultSelected">
                        <mat-option *ngFor="let q of questions" [value]="q.value">
                            {{q.displayValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="custom-mat-input full-width" appearance="outline">
                    <input matInput placeholder="Secret Answer" class="input-text">
                </mat-form-field>
            
            
                <div class="">
                    <a [routerLink]="'/Login'">Login?</a>
                </div>
            
                <div class="btn-container">
                    <button mat-stroked-button color="primary" class="cancel-button">Cancel</button>
                    <button mat-stroked-button color="primary" class="confirm-button" type="submit">Submit</button>
                </div>
            </form>
        </div>
    </div>
    

    <!-- <button mat-stroked-button color="primary" class="cancel-button" (click)="logout()">logout</button> -->
    
</div>