import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { Admin } from './admin.model';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(protected dataService: DataService) { }


  Post(data: any): Observable<any> {
    return this.dataService.post<Admin>(`Admin/ChangeConfig`, data);
  }

  Get(): Observable<any> {
    return this.dataService.get<Admin>(`Admin/GetAdminConfiguration`);
  }
}
