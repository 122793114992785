import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoaderService } from '../core/services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  //showLoader = false;

  constructor(public loaderService: LoaderService, private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    //this.init();
  }

  /*init () {
    this.loaderService.getLoaderObserver().subscribe((status) => {
        this.showLoader = status === 'start';
        this.cdRef.detectChanges();
    })
  }*/
}
