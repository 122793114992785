import { Injectable } from '@angular/core';
import { DataService } from 'src/app/core/services/data.service';
import { Observable, Subject } from 'rxjs';
import { Logout } from './logout.model';
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private adminInfo = new Subject<any>();  
  
  constructor(protected dataService: DataService) { 
  
  }
  
  setAdminStorage(data:any) {  
    this.adminInfo.next(data);  
  }  

  getAdminStorage(): Observable<any> {  
    return this.adminInfo.asObservable();  
  }
  
  removeAdminStorage(data:any){
    this.adminInfo.next(data)
  }

  Post(data :any): Observable<any> {
    return this.dataService.post<any>(`User/Login`,data );
  }

  Logout(data :Logout): Observable<any> {
    return this.dataService.post<any>(`User/LogOut`, data);
  }
}
