import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { ChangeSubscription } from './register-change-subscription.model';

@Injectable({
  providedIn: 'root'
})
export class RegisterChangeSubscriptionService {

  constructor(protected dataService: DataService) { }

  Get(): Observable<any> {
    return this.dataService.get<ChangeSubscription[]>(`SubscriptionBilling/GetPlansByPromo`);
  }
}
