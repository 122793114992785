<app-header></app-header>
<div class="page-container">
    <div class="img-container">
        <img src="./assets/not-found.svg" />
        <h1 class="heading">
            Sorry! Nothing was found
        </h1>
        <p class="sub-heading">
            The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
        </p>
        <button mat-stroked-button class="retry-btn">
            Retry</button>
        <a href="javascript:void(0);" class="link" [routerLink]="'/DealerPortal'">Return to Homepage</a>
    </div>
</div>