import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Admin } from './admin/admin/admin.model';
import { AdminService } from './admin/admin/admin.service';
import { AppService } from './app.service';
import { SessionDetailSerice } from './core/services/sessionDetail.service';
import { LoginService } from './login-screen/login/login.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title: string = 'Honda-Portals-project';
    loader: boolean = false;
    opened = false;
    showSideBarNavigation = false;

    constructor(private adminService: AdminService, private loginService: LoginService, private appService: AppService,
        private router: Router, private sessionService : SessionDetailSerice) {

    }

    ngOnInit(): void {
        this.setColorTheme();
        this.subscribeThemeChange();
        
        this.subscribeAdminGetInfo();
        this.subscribeAdminStorage();
        this.subscribeSubNavigationInfot();
    }

    subscribeThemeChange() {
       this.sessionService.getThemeInfo().subscribe(()=>{
        this.setColorTheme();
       });
    }

    subscribeAdminGetInfo() {
        this.adminService.Get().subscribe((result) => {
            if (result) {
                const adminModel: Admin = {} as Admin;
                adminModel.color = result.color && result.color != '' ? result.color : '';
                adminModel.projectTitle = result.projectTitle && result.projectTitle != '' ? result.projectTitle : '';
                adminModel.logoFilePath = result.logoFilePath && result.logoFilePath != '' ? result.logoFilePath : '';


                localStorage.setItem("color", adminModel.color);
                localStorage.setItem("projectTitle", adminModel.projectTitle);
                localStorage.setItem("logoFilePath", adminModel.logoFilePath);
                this.showSideBarNavigation = true;

                this.setColorTheme();// so on initilization the color changes accordingly
            }

        });
    }

    subscribeAdminStorage() {
        this.loginService.getAdminStorage().subscribe((res) => {
            if (res && res.admin) {
                const isAdmin = localStorage.getItem("is_admin");
                if (isAdmin && isAdmin === "yes") {
                    this.showSideBarNavigation = true; // only visible to default admin
                }
            } else {
                this.showSideBarNavigation = false; // only visible to default admin
            }
        });
    }

    subscribeSubNavigationInfot() {
        this.appService.getSubNavInfo().subscribe((res) => {
            console.log(res);
            if (res != undefined) {
                this.opened = res;
            }
        });
    }


    showLoader() {
        this.loader = !this.loader;
        if (this.loader) {
            console.log(document.body.style.overflow);
            document.body.style.overflow = "hidden";
            setTimeout(() => {
                this.loader = !this.loader;
                document.body.style.overflow = "";
            }, 2000);
        }
    }

    navigate(navigateUrl : string){
        this.router.navigate([navigateUrl]);
        // we are automatically closing the nav after user selects an option
        this.appService.toggleSubNavigation(false);
    }

    setColorTheme(){
        const color = this.sessionService.getAdminConfiguredColor();
        var r = document.querySelector(':root') as any;
        r.style.setProperty('--bgColor', color);
        
    }
}