import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusDTO } from '../shared/Models/Models';
import { Register } from './register.model';
import { RegisterService } from './register.service';
import { MatDialog } from '@angular/material/dialog';
import { StepCompletionStatusComponent } from '../shared/step-completion-status/step-completion-status.component';
import { Ca_State_prov, Us_State_prov } from '../shared/Models/Constants';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  stateProvDropdown: any;
  planNo: string;
  selectedSubPackage: string;

  questions = [
    { displayValue: 'Favourite pet name?', value: 'Favourite pet name?' },
    { displayValue: 'School name?', value: 'School name?' },
    { displayValue: 'Favourite place to go?', value: 'Favourite place to go?' }
  ];
  public defaultSelected = 'Favourite pet name?';
  countries = [
    { displayValue: 'United States', value: "US" },
    { displayValue: 'United Kingdom', value: "GB" },
    { displayValue: 'Canada', value: "CA" }
  ];
  constructor(public registerService: RegisterService, private route: ActivatedRoute, private router: Router, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    

    this.route.queryParamMap.subscribe((snap: any) => {
      if ((!snap.params.planNo || snap.params.planNo && snap.params.planNo.length === 0) ||
        (!snap.params.subPackage || snap.params.subPackage && snap.params.subPackage.length === 0)) {
        this.router.navigate(['/SelectPackage']);
      }


      this.planNo = snap.params.planNo;
      this.selectedSubPackage = snap.params.subPackage;

      this.initForm();
    }); // output: 

    this.stateProvDropdown = Us_State_prov; // setting default value
  }

  private initForm() {
    this.registerForm = new FormGroup({
      'first_name': new FormControl(null, Validators.required),
      'last_name': new FormControl(null, Validators.required),
      'phone': new FormControl(null, Validators.required),
      'address1': new FormControl(null, Validators.required),
      'address2': new FormControl(null),
      'email': new FormControl(null, Validators.required),
      'password': new FormControl(null, Validators.required),
      'city': new FormControl(null, Validators.required),
      'country_cd': new FormControl('US', Validators.required),
      'postal_cd': new FormControl(null),
      'state_prov': new FormControl(null),
      'secret_question': new FormControl('Favourite pet name?', Validators.required),
      'secret_question_answer': new FormControl(null, Validators.required),
      'plan_no': new FormControl(this.planNo, Validators.required),
      'alt_rate_schedule_no': new FormControl(this.selectedSubPackage, Validators.required),
    });
  }

  onCountryChange(event: any) {
    const selectedCountry = this.registerForm.value.country_cd;
    if (selectedCountry && selectedCountry === "US") {
      this.stateProvDropdown = Us_State_prov;
    } else if (selectedCountry && selectedCountry === "CA") {
      this.stateProvDropdown = Ca_State_prov;
    } else {
      this.stateProvDropdown = [];
    }
  }

  onSubmit() {
    const registerModel = this.registerForm.value;
    if (this.registerForm.valid) {
      this.registerService.Post(registerModel).subscribe((result) => {

        if (result && result.error_msg === "OK" && result.error_code === 0) {

          const acct_out = result.out_acct[0];
          const acct_no = acct_out.acct_no;
          const invoice_no = acct_out.invoice_info[0].invoice_no;
          const client_acct_id = acct_out.client_acct_id;
          
          // now we will only show message when user registers his card info as well.

            localStorage.setItem("client_acct_id", registerModel.email); // setting it in local storage
            this.router.navigate(['/PaymentInfo'], {queryParams: {
              an: acct_no, 
              in: invoice_no, 
              c_acct:client_acct_id
            }});
        }
        else {
          let statusData: StatusDTO = {
            title: 'Account could not be registered!',
            details: result && result.error_msg.length > 0 ? result.error_msg : "Some error occured, please try again.",
            stepSuccessful: false
          }

          let ref = this.dialog.open(StepCompletionStatusComponent,
            { height: '48vh', width: '42vw', data: statusData });

        }
      });
    }
    else {
      return;
    }

  }

  checkFormValidation() {

    if (!this.registerForm.get('firstName')?.valid && this.registerForm.get('firstName')?.touched) {
      return true;
    }

    if (!this.registerForm.get('lastName')?.valid && this.registerForm.get('lastName')?.touched) {
      return true;
    }

    if (!this.registerForm.get('phone')?.valid && this.registerForm.get('phone')?.touched) {
      return true;
    }

    if (!this.registerForm.get('address')?.valid && this.registerForm.get('address')?.touched) {
      return true;
    }

    if (!this.registerForm.get('email')?.valid && this.registerForm.get('email')?.touched) {
      return true;
    }

    if (!this.registerForm.get('password')?.valid && this.registerForm.get('password')?.touched) {
      return true;
    }

    if (!this.registerForm.get('secret_question')?.valid && this.registerForm.get('secret_question')?.touched) {
      return true;
    }

    if (!this.registerForm.get('secret_question_answer')?.valid && this.registerForm.get('secret_question_answer')?.touched) {
      return true;
    }

    if (!this.registerForm.get('city')?.valid && this.registerForm.get('city')?.touched) {
      return true;
    }

    if (!this.registerForm.get('country_cd')?.valid && this.registerForm.get('country_cd')?.touched) {
      return true;
    }

    return false;
  }

}
